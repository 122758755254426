// noinspection JSUnusedLocalSymbols

import $ from "jquery";
// import "./../styles/bootstrap-tagsinput.scss";
import 'typeahead.js';
require('input-case-enforcer')

import Bloodhound from "bloodhound-js";
// import 'bootstrap-tagsinput';
import '../outils/jquery-ui';
import {Tooltip} from "bootstrap";



//Cette fonction gère les Tooltip
export function manageTooltip() {
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltips.forEach(tooltip => {
        new Tooltip(tooltip,{trigger:'hover',placement:'bottom'});
    });

    // sur iphone, cela permet de masquer le tooltip en cas d'appui sur le bouton lorsqu'on reste sur la page.
    $('[data-bs-no-link]').on('click', function (){
        $('[id^="tooltip"]').hide();
    })
}

// Cette fonction gère le format d'entrée des inputs
export function manageInputCase(){
    $('.js-capitalize').caseEnforcer('capitalize');
    $('.js-uppercase').caseEnforcer('uppercase');
    $('.js-lowercase').caseEnforcer('lowercase');
}


export function manage_icones_menus() {
    //Cette fonction modifie l'apparence d'une icone menu en cas de survol
    let icones = $('.js-icone-menu')
    icones.on('mouseover', function () {
        if(!$(this).hasClass('js-open')){
            $(this).removeClass('text-primary').addClass('text-info')
            $(this).find(">:first-child").removeClass("fa-light").addClass("fa-solid");
            $(this).find(">:nth-child(2)").addClass('text-white');
            $(this).find(">:nth-child(3)").show();

        }
    })
    icones.on('mouseout', function () {
        if(!$(this).hasClass('js-open')) {
            $(this).removeClass('text-info').addClass('text-primary')
            $(this).find(">:first-child").removeClass("fa-solid").addClass("fa-light");
            $(this).find(">:nth-child(2)").removeClass('text-white');
            $(this).find(">:nth-child(3)").hide()

        }
    })
}
export function active_icone_menu(icone){
    //Cette fonction active l'apparence de l'icone icone
    icone.addClass('js-open').removeClass('text-primary').addClass('text-info')
    icone.find(">:first-child").removeClass("fa-light").addClass("fa-solid");
    icone.find(">:nth-child(2)").addClass('text-white');
    icone.find(">:nth-child(3)").hide()
}

export function desactive_icone_menu(icone){
    //Cette fonction désactive l'apparence de l'icone icone
    icone.removeClass('js-open').removeClass('text-info').addClass('text-primary')
    icone.find(">:first-child").removeClass("fa-solid").addClass("fa-light");
    icone.find(">:nth-child(2)").removeClass('text-white');
    icone.find(">:nth-child(3)").hide()

}

export function flashy_message(message){
//Cette fonction fait un flashy message success court avec le texte message
    let a='<div id="flashy-message" class="fixed-top d-flex justify-content-center " style="z-index:10000">\n' +
        '            <div class="flashy200 rounded rounded-2  bg-success" >\n' +
        '                <div class="js-flashy-message  text-center fw-semibold text-white mt-2 mx-2" >'+message+'</div>\n' +
        '            </div>\n' +
        '        </div>'
    $('body').prepend(a)
    $('#flashy-message')
        .fadeIn(200)
        .delay(900)
        .fadeOut(500, function() {
            $($('#flashy-message')).remove()
        })
}




//Cette fonction ouvre/ferme une div en cas de clic sur un bouton ayant la classe 'js-toogle-div'
export function toggleDivOnClick() {

    $('.js-toogle-div').on('click', function () {
        // id de la div à cibler
        let cible='#'+$(this).attr('data-cible')

        // on définit les variables
        let icone1= $(this).data('icone') //icone à l'affichage
        let icone2= $(this).data('icone-alt') //icone après le clic
        let lib1= $(this).data('lib') //libellé à l'affichage
        let lib2= $(this).data('lib-alt') //libellé après le clic
        let libCourt1= $(this).data('lib-court') //libellé court à l'affichage (iphone)
        let libCourt2= $(this).data('lib-court-alt') //libellé court après le clic (iphone)

                if ($(cible).hasClass('js-open')){
                $(cible).hide(200).removeClass('js-open');
                $(this).find(">:first-child").removeClass(icone2).addClass(icone1)
                $(this).find(">:nth-child(2)").html(lib1)
                $(this).find(">:nth-child(3)").html(libCourt1)
            }else{
                $(cible).show(300).addClass('js-open');
                $(this).find(">:first-child").removeClass(icone1).addClass(icone2)
                $(this).find(">:nth-child(2)").html(lib2)
                $(this).find(">:nth-child(3)").html(libCourt2)
            }

   })
}

// Cette fonction gère le scroll des barres de filtre (elle repositionne sur le dernier choix en cas de reload de la page)
 export function manageScrollBar() {
    // conditions de fonctionnement :
    //  - la page doit avoir un id='page' et un attribut 'data-type'  avec le nom de la page
    //  - la barre qui contient les filtres doit avoir comme class ='.scrolling-wrapper'
    //  - chaque item de la barre  (les filtres) doit avoir comme class 'js-filtre'
    //  - dans le js '_removeLocalStorage.js' il faut ajouter à l'array 'keysToRemove' le nom de la page pour permettre l'effacement des données

    // on replace le scroll de la barre si une position est mémorisée dans le localStorage.

     let barre = $('.scrolling-wrapper');
    let barre_scroll = localStorage.getItem($("#page").attr('data-type') + "_barre_scroll");
    if (barre_scroll !== null) {
        barre.scrollLeft(parseInt(barre_scroll, 10));
    }
    // On mémorise la position en cas de clic dans la barre
    $('.js-filtre').on('click', function () {
         localStorage.setItem($("#page").attr('data-type') + "_barre_scroll", $(this).scrollParent().scrollLeft()); // on mémorise la position scroll de la barre
    })
}


//Cette fonction gère l'affichage des icones du menu (solid sur hover, solid en cas de clic)
export function manageMenu() {

    //on va chercher le type de page
    let page=$('#page').attr('data-type');

    // on met en gras l'icône correspondant à la page en cours si elle existe
    if(typeof(page)!='undefined' ){
        let iconePage='#'+page
        $(iconePage).addClass('js-active').find(">:first-child").removeClass("fa-light").addClass("fa-regular")
    }

    let boutons=$(".js-icone");
    boutons.on('click', function (){

          boutons.find(">:first-child").removeClass("fa-regular").addClass("fa-light")
          if(!$(this).hasClass('js-active')){
              boutons.removeClass('js-active')
              $(this).find(">:first-child").removeClass("fa-light").addClass("fa-regular");
              $(this).addClass('js-active');

          }else{
              $(this).find(">:first-child").removeClass("fa-regular").addClass("fa-light");
              $(this).removeClass('js-active');
          }
 })

    boutons.on('mouseover', function (){
            if (!$(this).hasClass('js-active')){
                $(this).find(">:first-child").removeClass("fa-light").addClass("fa-regular");
            }
    })

    boutons.on('mouseout', function (){
        if (!$(this).hasClass('js-active')){
            $(this).find(">:first-child").removeClass("fa-regular").addClass("fa-light");
        }
    })
}

//Cette fonction gère l'ouverture et la fermeture de différentes div à partir d'un seul bouton
export function toggleDiv() {
    // CLASSE '.js-btn'
    // la classe 'js-btn' est destinée à être mise dans un bouton. Elle est liée à une autre div qui doit avoir la class="js-plus"
    // Elle permet d'ouvrir ou de fermer cette div dont l'ID est donnée dans l'attribut 'data-cible' du bouton.
    // La div qui doit être ouverte ou fermée doit avoir l'attribut "js-plus" et éventuellement "d-none" si elle doit être cachée au chargement de la page.

    //on permet le hide/show sur les div avec 'd-none'
    $('.d-none').removeClass('d-none').hide();

    let boutonsMenu=$(".js-btn");

    // traitement en cas de clic sur un bouton de la classe "js-btn".
    boutonsMenu.on('click', function () {
        let cible = '#' + $(this).attr('data-cible');


        if ($(cible).hasClass('js-open')) {
            $(cible).removeClass('js-open').hide(200);
        } else {
            $(".js-plus").removeClass('js-open').hide(200);
            $(cible).addClass('js-open').show(300);

            // focus si la data-focus est renseigné dans le bouton
            let attr = $(this).attr('data-focus');
            if (typeof attr !== 'undefined' && attr !== false && attr !=='') {
                $('#' + $(this).attr('data-focus')).focus();
            }

        }
    });

}



//Cette fonction permet d'effacer la variable "item" du storage local si elle existe
export function removeLocal(item){
    let search = localStorage.getItem(item);
    if (search !== null){
        localStorage.removeItem(item);
    }
}




// cette fonction permet de dessiner les tags dans un form
export function setupTags(){
    let $input = $('input[data-toggle="tagsinput"]');
    if ($input.length) {
        let source = new Bloodhound({
            local: $input.data('tags'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            datumTokenizer: Bloodhound.tokenizers.whitespace
        });
        // noinspection JSUnresolvedReference
        source.initialize();

        // noinspection JSUnresolvedReference
        $input.tagsinput({
            trimValue: true,
            focusClass: 'focus',
            typeaheadjs: {
                name: 'tags',
                source: source.ttAdapter()
            }
        });
    }
}

// cette fonction permet de créer un complete dans un form avec la liste data pour le champ data_champ
function bloodhood(data,data_champ) {
    // constructs the suggestion engine

    let show = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        // `states` is an array of state names defined in "The Basics"
        local: data
    });

    $("#"+data_champ).typeahead({
            hint: true,
            highlight: true,
            minLength: 1
        },
        {
            name: 'show',
            source: show
        })
}

// Cette fonction permet d'ajouter un élement d'une collection dans le modal
function addFormToCollection(e){
    const collectionHolder = document.querySelector('.' + e.currentTarget.dataset.collectionHolderClass);

    const item = document.createElement('li');

    item.innerHTML = collectionHolder
        .dataset
        .prototype
        .replace(
            /__name__/g,
            collectionHolder.dataset.index
        );

    collectionHolder.appendChild(item);
    collectionHolder.dataset.index++;
}


// cette fonction permet gérer l'affichage de message : type peut être light/danger/success
// ne pas oublier de mettre la div message au haut de la page (un exemple est dans repertoire_index.html.twig)
export function manageMessage(message, type="light"){

    if(type==='light'){
        $('#message').removeAttr('class').addClass('mt-3 border border-3 text-center Roboto fs-1 fw-semibold rounded bg-light text-danger').html(message)

    }else if(type==='danger'){
        $('#message').removeAttr('class').addClass('mt-3 border border-3 text-center Roboto fs-1 fw-semibold rounded bg-danger border-danger text-white').html(message)


    }else if(type==='success'){
        $('#message').removeAttr('class').addClass('mt-3 border border-3 text-center Roboto fs-1 fw-semibold rounded bg-success border-success text-white').html(message)



   }

}

function manageSubmitOffcanvas(){
    $('#submit-form').on('click', function (e) {
        let form=$('form');
        e.preventDefault();
        $.ajax({
            url: form.attr('action'),
            data: form.serialize(),
            method: 'POST'
        }).done(function (response) {

            if(response['code']===201){
                window.location.assign(response['referer'])
            }else{

                $("#offcanvas-body").html(response['bloc'])
                manageSubmitOffcanvas()
            }

        })
    })

}

function manageSubmit(myModal){
    $('#submit-form').on('click', function (e) {
        let form=$('form');
        e.preventDefault();
        $.ajax({
            url: form.attr('action'),
            data: form.serialize(),
            method: 'POST'
        }).done(function (response) {

            if(response['code']===201) {
                window.location.assign(response['referer'])
            }else if(response['code']===301){ //code spécifique pour gérer le retour de sauvegarde du modal du module répertoires
                if(response['default']){
                    let rep='#'+response['id']
                    $(rep).prop('checked',true)
                }
                myModal.hide()
            }else{
                $(".modal-content").html(response['modal'])
                manageSubmit(myModal)
            }

        })
    })
}


